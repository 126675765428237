module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"duration":800},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OnePlaybook","description":"Oneplaybook: manage your knowledge and work better with TiddlyWiki.","lang":"en","start_url":"/","background_color":"#fff5f7","theme_color":"#fff5f7","display":"standalone","icon":"src/assets/icon.png","legacy":"true","cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"ddd12a61ec51e4e336fd30bfde77052f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
